import { useUpdatesSubscription } from 'components/pages/updates/utils/use-updates-subscription';
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import { DataContext } from '../data-provider/DataProvider';
import { sortByPublishDate } from './utils/sort-by-publish-date';
import { usePrevious } from 'ahooks';
import { PageContext } from '../page-provider/PageProvider';
import { pageIds } from '../../../constants/pages';
import { updateLastViewedInFirestore } from 'components/pages/updates/utils/update-last-viewed-in-firestore';
import { ImpersonationContext } from '@monash/portal-frontend-common';

export const UpdatesContext = createContext();

export const UpdatesProvider = ({ children }) => {
  const [updates, setUpdates] = useState([]);
  const [error, setError] = useState(null);
  const { portalPreferences } = useContext(DataContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { selectedPageId } = useContext(PageContext);

  // The state for lastViewedUpdatesPage will be different to the
  // time in firestore, as it will only be updated upon leaving the updates page
  const [lastViewedUpdatesPage, setLastViewedUpdatesPage] = useState(null);
  const hasInitiatedLastViewed = useRef(false);

  useEffect(() => {
    if (
      portalPreferences?.lastViewedUpdatesPage &&
      hasInitiatedLastViewed.current === false
    ) {
      setLastViewedUpdatesPage(portalPreferences.lastViewedUpdatesPage);
      hasInitiatedLastViewed.current = true;
    }

    // For first time users to have never had lastViewedUpdatesPage set in firestore
    if (portalPreferences && !portalPreferences.lastViewedUpdatesPage) {
      setLastViewedUpdatesPage(Date.now());
      updateLastViewedInFirestore(currentUser);
    }
  }, [portalPreferences]);

  useUpdatesSubscription({
    onSnapshot: (updates) => {
      setUpdates(updates);
    },
    onError: (error) => {
      setError(error);
    },
  });

  const previousSelectedPageId = usePrevious(selectedPageId);

  useEffect(() => {
    // Update firestore when landing on the page
    if (selectedPageId === pageIds.UPDATES) {
      updateLastViewedInFirestore(currentUser);
    }

    // Clear badges when leaving the page
    if (previousSelectedPageId === pageIds.UPDATES) {
      setLastViewedUpdatesPage(Date.now());
    }
  }, [selectedPageId]);

  const finalUpdates = updates
    .map((update) => {
      const isNew =
        lastViewedUpdatesPage && update.publishDate > lastViewedUpdatesPage;
      return {
        isNew,
        ...update,
      };
    })
    .filter((update) => update.status === 'published')
    .sort(sortByPublishDate);

  const newCount = finalUpdates.filter((update) => update.isNew).length;

  return (
    <UpdatesContext.Provider value={{ updates: finalUpdates, newCount, error }}>
      {children}
    </UpdatesContext.Provider>
  );
};

export default UpdatesProvider;
