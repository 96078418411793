import { Card, Logo, InlineExternalLink, Tag } from '@monash/portal-react';
import c from './update-card.module.scss';
import fs from 'styles/font-styles.module.scss';
import { getDueText } from '../utils/get-due-text';

const UpdateCard = ({
  title,
  description,
  links,
  isDueSoon,
  isNew,
  dueDate,
  currentDate,
  ...restProps
}) => {
  const tagColor = isDueSoon ? 'orange' : 'grey';

  return (
    <Card tabIndex={0} {...restProps}>
      <div className={c.topSection}>
        <div className={c.logoWrapper} aria-label="By Monash University.">
          <Logo.Shield height={24} aria-hidden /> Monash University
        </div>

        <div className={c.tags}>
          {isNew && <Tag text="New!" color="blue" />}
          {dueDate && (
            <Tag text={getDueText({ dueDate, currentDate })} color={tagColor} />
          )}
        </div>
      </div>

      <h3 className={`${c.title} ${fs.heading} ${fs.md}`}>{title}</h3>
      <p className={c.description}>{description}</p>

      {links?.map(({ label, url, id }) => {
        const textLabel = label || url;

        return (
          textLabel && (
            <InlineExternalLink
              link={url}
              text={textLabel}
              className={c.link}
              key={id}
            />
          )
        );
      })}
    </Card>
  );
};

export default UpdateCard;
