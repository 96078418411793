import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Alert,
  ModalAction,
  ModalSection,
  useMedia,
  RouterState,
  TextField,
  useResponsiveValue,
} from '@monash/portal-react';
import {
  deepClone,
  ImpersonationContext,
  fsDocRef,
  fsWriteBatch,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { getRouteFromPageIndex } from 'components/utilities/pages';
import c from './delete-page-modal.module.scss';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';

const NODE_ID_MODAL_TITLE = 'deletePageModalTitle';
const NODE_ID_MODAL_CONTENT = 'deletePageModalContent';
const MODAL_TITLE = 'Delete page';
const MODAL_FOCUSABLES_SELECTOR =
  'button[type="button"]:not([disabled]), button[type="submit"]:not([disabled]), input[type="text"]';

const DeletePageModal = ({ pageId, pageData, open, setOpen }) => {
  const size = useMedia();
  const { currentUser } = useContext(ImpersonationContext);
  const { setPortalPreferences } = useContext(DataContext);
  const { redirect } = useContext(RouterState);
  const { addSnackbar } = useSnackbar();
  const { pages, pagesData, selectedPage } = useContext(PageContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [refreshModalFocusablesCount, setRefreshModalFocusablesCount] =
    useState(0);

  // responsive
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const ctaButtonSize = isMobile ? 'small' : 'medium';

  const [confirmInputValue, setConfirmInputValue] = useState('');
  const isDeleteValid = confirmInputValue.toUpperCase() === 'DELETE';
  const isBtnDeleteDisabled = isUpdating || !isDeleteValid;
  useEffect(() => {
    setRefreshModalFocusablesCount((prevCount) => prevCount + 1);
  }, [isBtnDeleteDisabled]);

  // delete page
  const deleteWidgetData = (widgetId, batch) => {
    // delete widget data doc
    const widgetDoc = fsDocRef(`users/${currentUser.uid}/widgets/${widgetId}`);
    batch.delete(widgetDoc);
  };

  // delete
  const deletePage = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    const batch = fsWriteBatch();

    // pages
    const deletePageName = pagesData.customPages[pageId]?.name;
    const newPages = deepClone(pagesData);
    delete newPages.customPages[pageId];
    const pageIndex = newPages.pageOrder.indexOf(pageId);
    newPages.pageOrder.splice(pageIndex, 1);

    // widgets
    const newWidgets = { ...newPages.widgets };
    await pageData?.widgetOrder.forEach((widgetId) => {
      // widgets in pages doc
      delete newWidgets[widgetId];
      // widgets in sub-collection
      deleteWidgetData(widgetId, batch);
    });
    newPages.widgets = newWidgets;

    // update pages
    const preferencesDoc = fsDocRef(`users/${currentUser.uid}`);
    batch.update(preferencesDoc, {
      'preferences.pages': newPages,
    });

    batch
      .commit()
      .then(() => handleDeleteSuccess(newPages, deletePageName))
      .catch(handleUpdateError)
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleDeleteSuccess = (newPages, deletePageName) => {
    // if the page being deleted is the first page, take them to the next page instead of the previous page;
    if (selectedPage !== 0) {
      redirect(getRouteFromPageIndex(pages, selectedPage - 1), {
        ignorePageHeadingFocus: true,
      });
    } else {
      redirect(getRouteFromPageIndex(pages, selectedPage + 1), {
        ignorePageHeadingFocus: true,
      });
    }

    setPortalPreferences((f) => {
      resetAppLiveMsgs();
      addSnackbar({
        message: `${deletePageName} page has been deleted.`,
        type: 'success',
      });
      return { ...f, pages: newPages };
    });
    closeModal();
  };

  const handleUpdateError = (error) => {
    setUpdateError(true);
    console.warn(
      '[updatePortalPreferences]: api call error, failed to delete page.',
      error
    );
  };

  // Modal
  const closeModal = () => {
    setOpen(false);
    setUpdateError(false);
    setConfirmInputValue('');
  };

  // Disable arrow-key navigation when the user is typing in the text field
  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      size={size}
      focusablesSelector={MODAL_FOCUSABLES_SELECTOR}
      refreshFocusables={refreshModalFocusablesCount}
      dismissOnHistoryNav={true}
      ariaLabel={MODAL_TITLE}
      ariaDescribedby={null}
    >
      <form onSubmit={deletePage}>
        <ModalSection
          onClose={closeModal}
          title={MODAL_TITLE}
          titleTabIndex={null}
          ariaLabelledby={NODE_ID_MODAL_TITLE}
          ariaDescribedby={NODE_ID_MODAL_CONTENT}
        >
          <div className={c.deletePage}>
            <p>
              By deleting the page you will permanently remove all the widgets
              and associated data.
            </p>
            <p>Type 'Delete' to confirm.</p>
            <div className={c.deleteTextField}>
              <TextField
                type="text"
                value={confirmInputValue}
                disabled={isUpdating}
                placeholder="Type delete here"
                error={() => !isDeleteValid}
                errorIcon={null}
                errorMsg={null}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                aria-labelledby={NODE_ID_MODAL_CONTENT}
              />
            </div>
          </div>
        </ModalSection>
        <ModalAction position="center">
          <div className={c.modalAction}>
            {updateError ? (
              <Alert type="error">
                We're having trouble deleting this page right now – please try
                again later.
              </Alert>
            ) : null}
            <div className={c.modalButtons}>
              <Button variant="text" size={ctaButtonSize} onClick={closeModal}>
                Cancel
              </Button>

              <Button
                disabled={isBtnDeleteDisabled}
                variant="delete"
                size={ctaButtonSize}
                loading={isUpdating}
                loadingMessage="Deleting page"
                data-tracking-event="custom-confirm-delete-page"
                type="submit"
              >
                Delete
              </Button>
            </div>
          </div>
        </ModalAction>
      </form>
    </Modal>
  );
};

export default DeletePageModal;
