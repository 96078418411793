import React, { useCallback, useContext, useEffect, useState } from 'react';
import c from './themer.module.scss';
import CustomTheme from './custom-theme/CustomTheme';
import SelectTheme from './select-theme/SelectTheme';
import ThemeSelector from './select-theme/theme-selector/ThemeSelector';
import { ThemeContext } from 'components/providers/theme-provider/ThemeProvider';
import { Tabs, useResponsiveValue } from '@monash/portal-react';
import DraggableWindow from 'components/ui/draggable-window/DraggableWindow';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { AccessibilityContext } from '@monash/portal-frontend-common';

const Themer = ({ showThemer, setShowThemer }) => {
  const { selectedTheme, updatePreferencesError } = useContext(ThemeContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { addSnackbar } = useSnackbar();
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);

  const headingText = 'Themer';
  const settingItems = [
    {
      name: 'Gallery',
      content: <SelectTheme />,
      children: <ThemeSelector theme={selectedTheme} />,
    },
    { name: 'Customise', content: <CustomTheme /> },
  ];

  const onCloseHandler = useCallback(() => {
    setShowThemer(false);
  }, [setShowThemer]);

  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);

  useEffect(() => {
    if (updatePreferencesError) {
      resetAppLiveMsgs();
      addSnackbar({
        message:
          "We're having trouble saving your theme preferences right now – please try again later",
        type: 'error',
      });
      console.warn(
        '[updatePortalPreferences]: api call error, failed to update theme preferences.',
        updatePreferencesError
      );
    }
  }, [updatePreferencesError]);

  return (
    <DraggableWindow
      title={headingText}
      onClose={onCloseHandler}
      isShowing={showThemer}
      focusTrapEnabled={true}
      keyboardDismissalEnabled={true}
      extraRootProps={{
        role: 'dialog',
        'aria-label': headingText,
      }}
      fullScreen={responsiveSize === 'S'}
    >
      <div className={c.Themer}>
        <div className={c.container}>
          <div className={c.selector}>
            <Tabs
              tabs={['Gallery', 'Customise']}
              selected={selectedTabIndex}
              onChange={setSelectedTabIndex}
            />
          </div>
          {showThemer ? settingItems[selectedTabIndex]?.content : null}
        </div>
      </div>
    </DraggableWindow>
  );
};

export default Themer;
