import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  RouterState,
  VirtuallyHidden,
  useResponsiveValue,
} from '@monash/portal-react';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import PageChanger from './PageChanger';
import useWindowSize from 'hooks/use-window-size';
import { calculateClosestPageIndex, getOverlap } from './utils';
import c from './main.module.scss';
import { isDefined, isDomNodeType } from '@monash/portal-frontend-common';
import { getRouteFromPageIndex } from 'components/utilities/pages';
import Placeholder from 'components/pages/placeholder/Placeholder';
import classNames from 'classnames';

export const MOBILE_RESPONSIVE = [
  {
    mq: '(max-width: 699px)',
    value: 'S',
  },
  {
    mq: '(min-width: 700px)',
    value: 'L',
  },
];

export const ID_SR_PAGE_HEADING = 'app-sr-page-heading';

const handleUnexpectedHorizontalScroll = (e) => {
  // 1. try preventing event (works when 'e.cancelable' is true)
  e.preventDefault();
  // 2. if event prevention didn't work, force reset 'scrollLeft'
  if (isDomNodeType(e.target) && e.target.scrollLeft !== 0) {
    e.target.scrollLeft = 0;
  }
};

const Main = () => {
  const {
    selectedPage,
    pages,
    allPagesHidden,
    onHiddenPage,
    setBgRefHandler,
    backgroundStyle,
    pageRefs,
    isProgrammaticScrolling,
    setIsProgrammaticScrolling,
  } = useContext(PageContext);
  const lastPath = useRef(null);

  const windowSize = useWindowSize().width;
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const { route, redirect } = useContext(RouterState);

  // states
  const [hasVerticalMainBeenScrolled, setHasVerticalMainBeenScrolled] =
    useState(false);

  // conditions
  const isMobile = responsiveSize === 'S';
  const noSelectedPage = selectedPage === null || !isDefined(selectedPage);
  const shouldDisplayPages = !(
    allPagesHidden ||
    onHiddenPage ||
    noSelectedPage
  );

  // refs
  const verticalMainRef = useRef();
  const bgRef = useRef();
  const pageChangeRef = useRef();

  // renders
  const renderVerticalPages = pages?.map((item, i) => {
    const active = selectedPage === i;
    const pageNameToken = (item.name || '').toLowerCase();
    const className = classNames(c.verticalPage, {
      [c.inactivePage]: !active,
    });

    return (
      <div
        id={getRouteFromPageIndex(pages, i)}
        key={pageNameToken}
        className={className}
        data-page={pageNameToken}
        data-active={active ? '' : null}
        inert={!active ? '' : null}
        aria-hidden={!active ? 'true' : 'false'}
        ref={pageRefs[i]}
      >
        {item.page()}
      </div>
    );
  });

  const renderPages = pages?.map((item, i) => {
    const active = selectedPage === i;
    const overlap = getOverlap(windowSize);
    const pageNameToken = !item.custom ? item.name.toLowerCase() : '';

    return (
      <div
        key={item.id}
        className={c.page}
        data-hidden={Math.abs(i - selectedPage) > 1 ? '' : null}
        data-page={pageNameToken}
        data-active={active ? '' : null}
        inert={!active ? '' : null}
        aria-hidden={!active ? 'true' : 'false'}
        style={
          active
            ? {
                transitionDelay: '.3s',
              }
            : {
                overflow: 'hidden',
                transform: `translate(calc(${(i - selectedPage) * 100}vw ${
                  i > selectedPage ? '-' : '+'
                } ${overlap}),0)`,
              }
        }
      >
        {item.page()}
      </div>
    );
  });

  // update route on vertical main pages scroll (user scroll only)
  const updateRoute = () => {
    if (isProgrammaticScrolling) return;
    const mainScrollTop = verticalMainRef?.current?.scrollTop;
    const pagePositions = pageRefs?.map((pageRef) => {
      const pagePosition = pageRef?.current?.getBoundingClientRect();
      return mainScrollTop + pagePosition?.top;
    });

    const closestPageIndex = calculateClosestPageIndex({
      pagePositions,
      mainScrollTop,
    });

    const path = pageRefs[closestPageIndex]?.current?.id;

    if (path) {
      if (lastPath.current !== path) {
        redirect(path);
      }
      lastPath.current = path;
    }
  };

  useEffect(() => {
    setBgRefHandler(bgRef);
  }, [setBgRefHandler]);

  useEffect(() => {
    // scroll vertical main page programmatically (initially)
    if (isMobile) {
      if (pages && shouldDisplayPages && !hasVerticalMainBeenScrolled) {
        setIsProgrammaticScrolling(true);
        verticalMainRef?.current?.scrollTo({
          top: pageRefs?.[selectedPage]?.current?.offsetTop,
        });
      }
    }

    // reset state
    if (!isMobile) {
      setHasVerticalMainBeenScrolled(false);
    }
  }, [pages, route.path, isMobile]);

  useEffect(() => {
    if (!route.data?.ignorePageHeadingFocus) pageChangeRef.current?.focus();
  }, [selectedPage]);

  return (
    <div id="contentContainer" className={c.Main} role="main">
      <VirtuallyHidden
        id={ID_SR_PAGE_HEADING}
        element="h1"
        ref={pageChangeRef}
        tabIndex={-1}
        text={Array.isArray(pages) ? pages[selectedPage]?.name || '' : ''}
      />
      {(allPagesHidden || onHiddenPage) && (
        <Placeholder onHiddenPage={onHiddenPage} />
      )}
      {shouldDisplayPages && !isMobile && (
        <div
          id="horizontalMain"
          className={c.Main}
          onScroll={handleUnexpectedHorizontalScroll}
        >
          <div
            className={c.background}
            ref={bgRef}
            style={{ ...backgroundStyle }}
          />
          {renderPages}

          {selectedPage > 0 && (
            <PageChanger
              link={getRouteFromPageIndex(pages, selectedPage - 1)}
              linkName={
                Array.isArray(pages) ? pages[selectedPage - 1]?.name || '' : ''
              }
              direction="left"
            />
          )}
          {selectedPage < pages?.length - 1 && (
            <PageChanger
              link={getRouteFromPageIndex(pages, selectedPage + 1)}
              linkName={
                Array.isArray(pages) ? pages[selectedPage + 1]?.name || '' : ''
              }
              direction="right"
            />
          )}
        </div>
      )}
      {shouldDisplayPages && isMobile && (
        <div
          className={c.VerticalMain}
          ref={verticalMainRef}
          id="VerticalMain"
          onScroll={() => {
            updateRoute();
            setHasVerticalMainBeenScrolled(true);
          }}
          style={{ ...backgroundStyle, width: '100vw', transform: 'unset' }}
        >
          {renderVerticalPages}
        </div>
      )}
    </div>
  );
};

export default Main;
